import React from 'react'
import { Button, Form, Grid, Header, Message, Segment } from 'semantic-ui-react'


class Login extends React.Component {
  constructor() {
    super()
    this.state = {
      mail:''
    }
  }
  handleMailChange(e) {
    this.setState({mail:e.target.value})
  }
  connect() {
      this.props.connect(this.state.mail);
  }
  fbConnect() {
      this.props.fbConnect();
  }
  render() {
    const  {loginSuccess, loginError} = this.props;
    return (
          <div className='login-form'>
            {/*
              Heads up! The styles below are necessary for the correct render of this example.
              You can do same with CSS, the main idea is that all the elements up to the `Grid`
              below must have a height of 100%.
            */}
            <style>{`
              body > div,
              body > div > div,
              body > div > div > div.login-form {
                height: 100%;
              }
            `}</style>
            <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
              <Grid.Column style={{ maxWidth: 450 }}>
                <Header as='h2' color='teal' textAlign='center'>
                  Se connecter
                </Header>
                <Form size='large' onSubmit={this.connect.bind(this)}>
                  <Segment stacked>
                    <Form.Input
                        fluid
                        icon='user'
                        iconPosition='left'
                        placeholder='Adresse E-mail'
                        type='email'
                        onChange={this.handleMailChange.bind(this)}
                        value={this.state.mail} />
                    <Button color='teal' fluid size='large' type='submit'>
                      Demande de connexion
                    </Button>
                    {loginSuccess && <Message color='green'>
                            {loginSuccess}
                        </Message>
                    }
                    {loginError && <Message color='red'>
                            {loginError}
                        </Message>
                    }
                  </Segment>
                  <Button onClick={this.fbConnect.bind(this)}>Connect with Facebook</Button>
                </Form>
              </Grid.Column>
            </Grid>
          </div>
        )
    }
}

export default Login

// <Image src='/logo.png' />
