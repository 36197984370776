import React from 'react';
import api from './api';

import { 
  MAX_FRESH_WORDS, 
  FRESH_WORD_MAX_SCORE, 
  weightedRandom, 
  elapsedUntillNow, 
  expectedWaitDuration, 
  needsToBeRecalled, 
  recallProba
} from './common/words_timing';


const Context = React.createContext('words');

class Provider extends React.Component {
  constructor() {
    super();
    this.state = {
      dictionnary: null,
      myWords: null,
      isConnected: api.isConnected,
      nextWords: [],
      error: null,
      currentWord: null,
      wordsHistory: [],
      loginSuccess: '',
      loginError: '',
      scores: null,
      recallCount: 0, 
      knownCount : 0,
      forgottenCount: 0,
      isUsingKatakana: false
    }
  }
  componentWillMount() {
    this.loadMyWords().then(() => {
      this.nextWord();
    });
  }
  disconnect(e) {
    console.log('disconnected',{error:e});
    if(e.response && e.response.status === 401) {
      api.logout();
      this.setState({isConnected: false});
    }
    else {
      //TODO:
    }
  }
  useKatakana(b) {
    this.setState({isUsingKatakana: b});
  }
  fbConnect() {
    window.location.href = api.baseURL+'/__redirect/auth/facebook';
  }
  mailConnect(mail) {
    this.setState({loginSuccess: '...'});
    api.post('/mail/auth', {recipient:mail})
    .then((res) => {
      this.setState({loginSuccess: 'Consultez vos mails'});
    })
    .catch(this.setState({loginError: 'Erreur'}))
  }
  onResult(result){
    console.log('updateWord', this.state.currentWord, result);
    
    // If the word does not need to be recalled, then don't increase its score
    // Just update the last_view field.
    if(!needsToBeRecalled(this.state.currentWord) && result > 0) 
    {
      result = 0
    }
    const currentWord = {
      ...this.state.currentWord,
      score: this.state.currentWord.score + result,
      last_view: new Date()
    }
    const myWords = this.state.myWords.map((w) => {
      if(w.id === currentWord.id) {
        return currentWord;
      }
      return w;
    });
    this.setState({myWords}, () => {
      this.pushApiCommand('updateWordScore', { 
        score: currentWord.score, 
        word_id: currentWord.id,
        questioned: currentWord.questioned,
        last_view: currentWord.last_view.toISOString().replace('Z', '')
      })
      this.nextWord();
    });
    
  }
  pushApiCommand(command, value) {
    console.log({command, value});
    api.post('/me/execCommand', {
      command,
      value
    })
    .catch(this.disconnect.bind(this))
  }
  nextWord() {
    const word_id_match = window.location.search.match(/^\?word_id=([\d]+)$/)

    let currentWord = null;

    let words_probas = this.state.myWords.map((r,i) => {
      r.p = recallProba(r)
      return { 
        word:   r, 
        p:      r.p,
        el:     elapsedUntillNow(r.last_view),
        target: Math.pow(5, r.score),
        score:  r.score
      }
    });
    let probas = words_probas
      .filter(r => r.p < 0.95 && r.p > 0.8)
      .sort((a, b) => a.p - b.p);
    
    console.log(word_id_match)
    if(word_id_match)
    {
      window.history.pushState({}, window.document.title, '/');
      console.log(this.state.myWords)
      const words_match = this.state.myWords.filter(w => w.id == word_id_match[1])
      console.log(words_match)
      currentWord = words_match.length && words_match[0]
    }
    if(!currentWord)
    {
      if(probas.length) {
        console.log(probas.length+' to recall');
        console.log(probas.map(p=>p.p));
        
        probas = weightedRandom(probas, (p) => 1/p);
        console.log(probas.p);
        currentWord = probas.word;
      } 
      else 
      {
        let fresh_words_count = words_probas
            .filter(r => r.p > 0.8)
            .filter(r => r.score <= FRESH_WORD_MAX_SCORE).length
            
        if(fresh_words_count <= MAX_FRESH_WORDS)
        {
            probas = words_probas
              .filter(r => r.p <= 0.8)
              .sort((a, b) => a.p - b.p);
            
            if(probas.length) {
              console.log('add new word from forgotten ones');
              console.log(probas.map(p=>p.p));
              
              probas = probas[probas.length-1];
              console.log(probas.p);
              currentWord = probas.word;
            } 
        }
        if(!currentWord)
        {
          let knownWords = words_probas
              .filter(r => r.p > 0.95);
          console.log('too many ('+fresh_words_count+' > '+MAX_FRESH_WORDS+') learning words so take a random one');
          const i = Math.floor(Math.random()*knownWords.length);
          currentWord = {
            ...knownWords[i].word,
            training: true
          }
        }
      }
    }
    
    
    const recallCount = words_probas
      .filter(r => r.p > 0.8)
      .filter(r => r.score <= FRESH_WORD_MAX_SCORE).length
    const knownCount = words_probas
      .filter(r => r.p > 0.8)
      .filter(r => r.score > FRESH_WORD_MAX_SCORE).length
    const forgottenCount =  words_probas
      .filter(r => r.p < 0.8)
      .length;
    this.setState({currentWord, recallCount, knownCount, forgottenCount});
  }
  loadMyWords(callback) {
    this.loadScores();
    return new Promise((acc, rej) => {
      if(this.state.myWords === null)
      {
        api.get('/me/words').then((res) => {
          const myWords = res.data.entries.map(w => {
            w.last_view = new Date(w.last_view.replace(' ', 'T')+'Z');
            return w;
          })
          this.setState({myWords});
          acc();
        }).catch((err) => {
          this.disconnect(err)
          rej();
        });
      }
    });
  }
  loadDictionnary() {
    if(!this.state.dictionnary)
      /* to limit the number of words:
      {
        params:{
          start:0,
          count:10000
        }
      }*/
      api.get('/me/dictionnary').then((res) => {
        this.setState({dictionnary: res.data.entries});
      })
    return true;
  }
  loadScores() {
    api.get('/me/getScores').then((res) => {
      this.setState({scores: res.data});
    })
    .catch(err => console.log('error while loading scores', err))
  }
  deleteWordSuccess(entry_id) {
    this.setState({
      myWords: (this.state.myWords === null
        ? null
        : this.state.myWords.filter((e) => {
            return e.id !== entry_id
          })
      ),
      dictionnary: (
        this.state.dictionnary === null
          ? null
          : this.state.dictionnary.map((e) => {
              return e.id !== entry_id ? e : {...e, score:null}
            })
        )
      });
  }
  addWordsSuccess(myWords) {
    
    myWords = myWords.map(w => {
      w.last_view = new Date(w.last_view+'Z');
      return w;
    });
    if(this.state.myWords !== null) {
      myWords.push(...this.state.myWords);
    }

    this.setState({
      myWords,
      dictionnary:(
        this.state.dictionnary === null
          ? null
          : this.state.dictionnary.map((e) => {
            const found = myWords.filter(addw => addw.id == e.id);
            return found.length ? found[0] : e
          })
        )
      });
  }
  deleteWord(entry_id) {
    api.delete('/me/word', {data:{entry_id}}).then((res) => {
      this.deleteWordSuccess(entry_id);
    }).catch(this.disconnect.bind(this))
  }
  toggleWord(entry_id, v) {
    if(v) {
      api.post('/me/word', {entry_id}).then((res) => {
        this.addWordsSuccess(res.data)
      }).catch(this.disconnect.bind(this))
    } else {
      api.delete('/me/word', {data:{entry_id}}).then((res) => {
        this.deleteWordsSuccess(entry_id);
      }).catch(this.disconnect.bind(this))
    }
  }
  addRandowWordFromGroup(group_id) {
    api.post('/me/addRandowWordFromGroup', {group_id}).then((res) => {
      this.addWordsSuccess(res.data)
    }).catch(this.disconnect.bind(this))
  }

  render() {
      return <Context.Provider value={{
        toggleWord: this.toggleWord.bind(this),
        loadDictionnary: this.loadDictionnary.bind(this),
        loadMyWords: this.loadMyWords.bind(this),
        fbConnect: this.fbConnect.bind(this),
        onResult: this.onResult.bind(this),
        deleteWord: this.deleteWord.bind(this),
        addRandowWordFromGroup: this.addRandowWordFromGroup.bind(this),
        useKatakana: this.useKatakana.bind(this),
        mailConnect: this.mailConnect.bind(this),
        ...this.state
      }}>
        {this.props.children}
      </Context.Provider>
  }
}

export default {
  Consumer: Context.Consumer,
  Provider: Provider
};
