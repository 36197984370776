import React from 'react';
import { Container } from 'semantic-ui-react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom'


import App from './App';
import WordManager from './WordManager';
import Dictionnary from './Dictionnary';
import Context from './Context';
import Login from './Login';



export default () => <Container>
  <Context.Provider>
    <Context.Consumer>
      {_ => !_.isConnected
        ? <Login connect={_.mailConnect} fbConnect={_.fbConnect} loginSuccess={_.loginSuccess || ''} loginError={_.loginError || ''} />
        : <Router>
            <div>
              <ul>
                <li><Link to="/">Apprendre</Link></li>
                <li><Link to="/manage">Gérer</Link></li>
                <li><Link to="/dictionnary">Dictionnaire</Link></li>
              </ul>
        
              <hr/>
              <Routes>
                <Route exact path="/" Component={App}/>
                <Route path="/manage" Component={WordManager}/>
                <Route path="/dictionnary" Component={Dictionnary}/>
              </Routes>
            </div>
          </Router>
      }
    </Context.Consumer>
  </Context.Provider>
</Container>

 /*
            
        */
