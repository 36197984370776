import React from 'react'
import Word from './Word';
import Context from './Context';
import { Loader, Message } from 'semantic-ui-react'


class App extends React.Component {


  render() {
    return <Context.Consumer>{
        _ => (
          _.error === 'NO_WORD'
          ? <Message>
              <Message.Header>Vous n'avez pas de mot</Message.Header>
              <p>
                Ajoutez un mot dans l'onglet "Gérer" ou "Dictionnaire"
              </p>
            </Message>
          : (!_.currentWord
            ? <div><Loader active inline='centered' /></div>
            : <div>
              {_.forgottenCount+' mots oubliés, '+_.recallCount+' mots à revoir, '+_.knownCount+' mots appris'}
                <Word
                  isUsingKatakana={_.isUsingKatakana}
                  onResult={_.onResult}
                  word={_.currentWord} />
              </div>
          )
        )
      }
    </Context.Consumer>
  }
}

export default App
