import React from 'react'
import { Popup } from 'semantic-ui-react'
import hepburn from 'hepburn'

const synth = window.speechSynthesis;
let japaneseVoices = null;

export const speak = (t) => {
  console.log('speak',t);
  if(japaneseVoices === null) {
    japaneseVoices = synth.getVoices().filter(v => v.lang === 'ja-JP')
  }
  const utterThis = new SpeechSynthesisUtterance(t);
  if(japaneseVoices.length){
    utterThis.voice = japaneseVoices[0];
  }
  //utterThis.pitch;
  //utterThis.rate;
  synth.speak(utterThis);
}

export default ({hiragana, isUsingKatakana}) => <Popup
    trigger={<span style={{cursor:'pointer'}} className='hiragana-speech' onClick={() => speak(hiragana)}>
      {isUsingKatakana ? hepburn.toKatakana(hepburn.fromKana(hiragana)) : hiragana}
    </span>}
    content={hepburn.fromKana(hiragana).toLowerCase()}
  />
