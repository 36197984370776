import React from 'react'
import { Header, Table, Pagination, Loader, Button, Label, Icon } from 'semantic-ui-react'

import Hiragana from './Hiragana';
import Context from './Context';


const Row = ({row, deleteWord}) =>
    <Table.Row>
      <Table.Cell>
        <Header as='h4'>
          <Header.Content>
              {row.jp}
            <Header.Subheader><Hiragana hiragana={row.hiragana} /></Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>
      <Table.Cell>
          {row.fr}
      </Table.Cell>
      <Table.Cell>
          {row.score}
      </Table.Cell>
      <Table.Cell>
          {row.N5 ? 'N5' : ''}
      </Table.Cell>
      <Table.Cell>
          {row.questioned}
      </Table.Cell>
      <Table.Cell>
        <i style={{cursor:'pointer'}} onClick={() => deleteWord(row.id)} className='trash icon'></i>
      </Table.Cell>
    </Table.Row>

const computeScoreSum = (scores) => {
  return scores.reduce((t,el) => {
    return (Math.pow(scoreFactor, el.score)*el.count + t);
  }, 0);
}

class WordsScoreSummary extends React.Component {
  render() {

    const scores = this.props.scores;
    if(!scores || scores.length == 0) return <div></div>;

    let sumScores = 0;
    let maxScores = scores[0].count;
    for(let s of scores) {
      if(s.count > maxScores) maxScores = s.count;
      sumScores += s.count;
    }
    console.log(this.props, maxScores);

    return scores.map((s, i) => <div key={i}>
      <span style={{display:'inline-block',width:30}}>{s.score}</span>
      <span style={{display:'inline-block',width:30}}>{'->'}</span>
      <span style={{display:'inline-block',width:50}}>{s.count}</span>
      <span className="score-weight" style={{width: (s.count / maxScores * 70) +
        '%'}}></span>
    </div>)
  }
}

class WordManager extends React.Component {
  constructor() {
    super();
    this.state = {
      userWords:[],
      activePage: 1,
      paginationItemCount: 20
    };
  }
  handlePaginationChange(e, { activePage }) {
    this.setState({activePage});
  }
  requestNotification() {
    Notification.requestPermission(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        var notification = new Notification("Coucou!!");
      }
    });
  }
  render() {
    
    const scoreFactor = 1.5;
    
  
    
    return <Context.Consumer>{_ => !_.myWords ? (_.loadMyWords() && <div><Loader active inline='centered' /></div>) :
      <div>
        {("Notification" in window) 
        && Notification.permission !== "granted" 
        && <div><Button onClick={() => this.requestNotification()}>Autoriser les notifications</Button></div>}
        <Button onClick={() => _.addRandowWordFromGroup(1)}>Ajouter un mot N5 aléatoire</Button>
        <Label>
          <Icon name='info' />{' '}{_.myWords.length} Mots
        </Label>
        <Label>
          <Icon name='info' />{' '}{_.myWords.filter(m => m.N5).length} Mots N5
        </Label>
        { _.scores &&
          <div>
            <h2>Statistiques</h2>
            <WordsScoreSummary scores={_.scores} />
          </div>
        }
        <div>
        {
          _.isUsingKatakana ? 
             <Button onClick={() => _.useKatakana(false)}>Utiliser les Hiragana</Button>
             : <Button onClick={() => _.useKatakana(true)}>Utiliser les Katakana</Button>
        }
        </div>
        <h2>Ajouts Récents</h2>
        <Table basic='very' celled collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Japonais</Table.HeaderCell>
              <Table.HeaderCell>Tranduction</Table.HeaderCell>
              <Table.HeaderCell>Score</Table.HeaderCell>
              <Table.HeaderCell>N5</Table.HeaderCell>
              <Table.HeaderCell>Question</Table.HeaderCell>
              <Table.HeaderCell>Supprimer</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {_.myWords.slice((this.state.activePage - 1) * this.state.paginationItemCount, this.state.activePage * this.state.paginationItemCount)
              .map((r, key) => <Row key={key} row={r} deleteWord={_.deleteWord} />)}
          </Table.Body>
        </Table>
        <Pagination
          defaultActivePage={this.state.activePage}
          totalPages={Math.ceil(_.myWords.length / this.state.paginationItemCount)}
          onPageChange={this.handlePaginationChange.bind(this)} />
      </div>}
    </Context.Consumer>
  }
}

export default WordManager;
