import axios from 'axios';

const baseURL = window.location.origin.match(/localhost/) ?
  'http://localhost:3001' :
   ( localStorage.getItem('apiurl') 
    ? localStorage.getItem('apiurl')
    : window.location.origin
   )

const headers = {}


const m = window.location.href.match(/^([^?]+)\?jwt=([^#&]+)/);

let jwt = null;
if(m) {
  jwt = m[2];
  if(localStorage) {
    localStorage.setItem('jwt', jwt);
  }
  window.history.replaceState({}, "", "/");

} else {
  if(localStorage) {
    jwt = localStorage.getItem('jwt');
  }
}
if(jwt) {
  headers['Authorization'] = 'bearer '+jwt;
}

const api = axios.create({
  baseURL: baseURL+'/api',
  headers
});
api.isConnected = jwt != null;
api.baseURL = baseURL;
api.logout = () => localStorage.setItem('jwt', null);



export default api;
