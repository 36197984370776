import React from 'react'
import { Dropdown, Header, Checkbox, Table, Pagination, Loader, Input } from 'semantic-ui-react'

import Hiragana from './Hiragana';
import Context from './Context';
import hepburn from 'hepburn'


const Row = ({row, toggleWord}) =>
    <Table.Row>
      <Table.Cell>
        <Header as='h4'>
          <Header.Content>
              {row.jp}
            <Header.Subheader><Hiragana hiragana={row.hiragana} /></Header.Subheader>
          </Header.Content>
        </Header>
      </Table.Cell>
      <Table.Cell>
          {row.fr}
      </Table.Cell>
      <Table.Cell>
          {row.N5 ? 'N5' : ''}
      </Table.Cell>
      <Table.Cell>
        <Checkbox onClick={() => toggleWord(row.id, row.score == null)} toggle checked={row.score != null}/>
      </Table.Cell>
    </Table.Row>


class Dictionnary extends React.Component {
  constructor() {
    super()
    this.state = {
      userWords:[],
      activePage: 1,
      paginationItemCount: 20,
      search:'',
      searchGroup: [],
      searchType: []
    }
  }
  handlePaginationChange(e, { activePage }) {
    this.setState({activePage})
  }
  onSearchChange(e) {
    const search = e.target.value;
    console.log({search});
    this.setState({search});
  }
  onSearchGroupChange(e, {name, value}) {
    const searchGroup = value;
    console.log({searchGroup});
    this.setState({searchGroup});
  }
  onSearchTypeChange(e, {name, value}) {
    const searchType = value;
    console.log({searchType});
    this.setState({searchType});
  }
  filterDico(dico) {

    console.log('before: '+dico[0]);
    console.log('before: '+dico[0].fr_search);
    console.log('before: '+this.state.search);
    console.log('before: '+dico[0].fr_search.match(this.state.search));
    console.log('before: '+dico.length);
    if(this.state.search) {
      let sea = new RegExp(this.state.search, 'i');
      let seaHir = new RegExp(hepburn.toHiragana(this.state.search), 'i');
      
      dico = dico.filter(w => (
           w.fr_search.match(sea)
        || w.jp.match(sea)
        || w.hiragana.match(sea)
        || w.hiragana.match(seaHir))
      )
    }
    console.log('found: '+dico.length);
    if(this.state.searchGroup.length) {
      console.log(this.state.searchGroup);
      console.log(dico.length);
      this.state.searchGroup.forEach(f => {
        dico = dico.filter(w => w[f]);
      })
    }
    if(this.state.searchType.length) {
      dico = dico.filter(w => this.state.searchType.indexOf(w.type) !== -1);
    }
    return dico;
  }
  render() {


    return <Context.Consumer>{_ => !_.dictionnary ? (_.loadDictionnary() && <div><Loader active inline='centered' /></div>) :
      <div>
        <Input style={{textAlign: 'center', width:'100%'}}
          value={this.state.search}
          onChange={this.onSearchChange.bind(this)}
          placeholder={'Chercher un mot?'} action>
          <input />
          <Dropdown
            onChange={this.onSearchGroupChange.bind(this)}
            compact fluid multiple selection
            value={this.state.searchGroup}
            placeholder='Groupe' options={[{key:'N5', value:'N5', text:'N5'}]} />
          <Dropdown
            onChange={this.onSearchTypeChange.bind(this)}
            compact fluid multiple selection
            value={this.state.searchType}
            placeholder='Type' options={[
              {key:1, value:1, text:'verbe (groupe 1)'},
              {key:2, value:2, text:'verbe (groupe 4)'},
              {key:3, value:3, text:'verbe (irrégulier)'},
              {key:4, value:4, text:'adjectif variable'},
              {key:5, value:5, text:'adjectif invariable'},
              {key:6, value:6, text:'nom commun'},
              {key:7, value:7, text:'nom propre'},
              {key:8, value:8, text:'particule'},
              {key:9, value:9, text:'adverbe'},
              {key:10, value:10, text:'expression'},
              {key:11, value:11, text:'proverbe'},
              {key:12, value:12, text:'onomatopée'},
              {key:13, value:13, text:'nombre, numéral'},
              {key:14, value:14, text:'suffixe'}
            ]} />
        </Input>
        <Table basic='very' celled collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Japonais</Table.HeaderCell>
              <Table.HeaderCell>Tranduction</Table.HeaderCell>
              <Table.HeaderCell>N5</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.filterDico(_.dictionnary)
              .slice((this.state.activePage - 1) * this.state.paginationItemCount, this.state.activePage * this.state.paginationItemCount)
              .map((r, key) => r.id == null ? (console.log(r), null) : <Row key={key} row={r} toggleWord={_.toggleWord} />)}
          </Table.Body>
        </Table>
        <Pagination
          defaultActivePage={this.state.activePage}
          totalPages={Math.ceil(_.dictionnary.length / this.state.paginationItemCount)}
          onPageChange={this.handlePaginationChange.bind(this)} />
      </div>}
    </Context.Consumer>
  }
}

export default Dictionnary;
