

export const MAX_FRESH_WORDS = 25;
export const FRESH_WORD_MAX_SCORE = 6; // 7-> 1 jour ; 6 -> 4 heures

function identity(s) { return s; };

function weight_add(weight) {
  return function(s, e) { 
    return weight(e.p) + s; 
  }
}

export function weightedRandom(list, weight) {
  if(!weight) {
    weight = identity;
  }

  const sum = list.reduce(weight_add(weight), 0);
  const choice = Math.random() * sum;
  let cumulated = 0;
  for(const i in list)
  {
    cumulated += weight(list[i].p);
    if(choice <= cumulated) {
      return list[i];
    }
  }
  return list[list.length-1];
}

export function elapsedUntillNow(s)
{
  return (new Date().getTime() - s.getTime())/1000; 
}
export function expectedWaitDuration(score)
{
  if(score < 8) {
    return Math.pow(5, score) ;
  }
  if(score < 13) {
    // Math.pow(5, 8) - Math.pow(4.3, 8) = 273743
    return 273743.0 + Math.pow(4.3, score);
  }
  // previous function derivative at the point 13 = 250628586
  // previous function at 0 - the linear value at 13 = -3086071563
  const value = 250628586.0 * score - 3086071563.0;
  if(value < 0 || value > 1000000000) {
    return 1000000000;
  }
  return value;
}
export function needsToBeRecalled({last_view, score}) 
{
  return elapsedUntillNow(last_view) >= expectedWaitDuration(score)
}
export function recallProba({last_view, score}) {
  return Math.pow(0.95, elapsedUntillNow(last_view) / expectedWaitDuration(score));
}

