import React from 'react'
import { Button, List, Icon } from 'semantic-ui-react'
import hepburn from 'hepburn'

import { expectedWaitDuration } from './common/words_timing';
import { speak, default as Hiragana } from './Hiragana';
import Context from './Context';

const Show = ({show}) => (
  show
  ? <i className='unhide icon'></i>
  : <i className='hide icon'></i>
);

class Word extends React.Component {
  constructor() {
    super();
    this.state = {
      showRomaji: false,
      showHiragana:true,
      showKanji:false,
      showTranslation:true,
      checking:false
    }
  }
  check() {
    this.setState({checking:true});
  }
  result(v) {
    this.setState({checking:false});
    this.props.onResult(v);
  }
  iKnowIt() {
    this.setState({checking:false});
    this.props.onResult(1);
  }
  iDontKnowIt() {
    this.setState({checking:false});
    this.props.onResult(-1);
  }
  iDontKnowItBadly() {
    this.setState({checking:false});
    this.props.onResult(-5);
  }
  handleKey(event) {
    event.preventDefault();
    var x = event.charCode || event.keyCode;
    if(event.key == 'a')
    {
      speak(this.props.word.hiragana);
    }
    else if(x === 9) // Tab
    {
      if(!this.state.checking) {
        this.check();
      }
    }
    else if(x === 38) // UP / I know it
    {
      if(this.state.checking) {
        this.iKnowIt();
      }
    }
    else if(x === 40) { //or DOWN / I don't know it
      if(this.state.checking) {
        this.iDontKnowIt();
      }
    }
    else if(event.key == 'p') { 
      if(this.state.checking) {
        const addToScore = 12 - this.props.word.score;
        console.log('set to one year (score 12)',this.props.word.score,'+',addToScore)
        this.result(addToScore);
      }
    }
    else if(event.key == 'o') { 
      if(this.state.checking) {
        const addToScore = 11 - this.props.word.score;
        console.log('set to 4 month (score 11)',this.props.word.score,'+',addToScore)
        this.result(addToScore);
      }
    }
    else if(event.key == 'i') { 
      if(this.state.checking) {
        const addToScore = 10 - this.props.word.score;
        console.log('set to 1 month (score 10)',this.props.word.score,'+',addToScore)
        this.result(addToScore);
      }
    } else console.log(event.key);
  }
  ignoreKeys(event) {
    var x = event.charCode || event.keyCode;
    if(x === 9
     ||x === 39 // Tab
     ||x === 38 // UP / I know it
     ||x === 40) { //or DOWN / I don't know it
      event.preventDefault();
    }
  }
  componentDidMount() {
    this.handleKeyBinded = this.handleKey.bind(this);
    document.addEventListener('keyup', this.handleKeyBinded);
    document.addEventListener('keydown', this.ignoreKeys);
    document.addEventListener('keypress', this.ignoreKeys);
  }
  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyBinded)
    document.removeEventListener('keydown', this.ignoreKeys);
    document.removeEventListener('keypress', this.ignoreKeys);
  }
  displayArrows() {
    const {word} = this.props;
    const scoreToTime = (score) => {
      let units = [['s',60], ['min',60], ['h',24], ['j',30], ['mo',12], ['a',10000]];
      let t = expectedWaitDuration(Math.max(0, score));
      while(t > units[0][1]) {
        t /= units[0][1];
        units = units.slice(1);
      }
      return Math.round(t)+units[0][0];
    };
    console.log('current word', word);
    return <div style={{textAlign: 'center', marginTop: 30}}>
      {
        this.state.checking
        ? <span className="icon-action-container">
            {word.score > 1 && <Button color='red' onClick={() => this.iDontKnowItBadly()}>{scoreToTime(word.score-5)}</Button>}
            <Button onClick={() => this.result(-2)}>{scoreToTime(word.score-2)}</Button>
            <Button onClick={() => this.iDontKnowIt()}>{scoreToTime(word.score-1)}</Button>
            <span style={{display:'inline-block', width:50}}></span>
            <br/>
            <br/>
            <br/>
            <br/>
            {word.training 
            ? <Button primary onClick={() => this.result(0)}>{scoreToTime(word.score)}</Button>
            : (word.p < 0.9 ? [
                <Button key="1" primary onClick={() => this.result(0)}>{scoreToTime(word.score)}</Button>,
                <Button key="2" onClick={() => this.result(1)}>{scoreToTime(word.score+1)}</Button>
              ] : [
                <Button key="knowit" primary onClick={() => this.iKnowIt()}>{scoreToTime(word.score+1)}</Button>,
                <Button key="knowitwell" onClick={() => this.result(2)}>{scoreToTime(word.score+2)}</Button>
              ])
            }
            <br/>
            <br/>
            <br/>
            <br/>
            <Button key="plus3" onClick={() => this.result(3)}>{scoreToTime(word.score+3)}</Button>
            <Button key="plus4" onClick={() => this.result(4)}>{scoreToTime(word.score+4)}</Button>
            <Button key="plus5" onClick={() => this.result(5)}>{scoreToTime(word.score+5)}</Button>
            <br/>
            <Button key="plus6" onClick={() => this.result(6)}>{scoreToTime(word.score+6)}</Button>
            <Button key="plus7" onClick={() => this.result(7)}>{scoreToTime(word.score+7)}</Button>
            <Button key="plus8" onClick={() => this.result(7)}>{scoreToTime(word.score+8)}</Button>
            <br/>
            <Button key="plus9" onClick={() => this.result(9)}>{scoreToTime(word.score+9)}</Button>
            <Button key="plus10" onClick={() => this.result(10)}>{scoreToTime(word.score+10)}</Button>
            <Button key="plus11" onClick={() => this.result(11)}>{scoreToTime(word.score+11)}</Button>
          </span>
        : <span>
            <Icon onClick={() => this.check()} style={{fontSize:48}} name="question circle" />
          </span>
      }

    </div>  }
  render() {
    const {word, isUsingKatakana} = this.props;
   return <div>
      {word.training && 'training mode'}
      <List divided verticalAlign='middle'  size='massive'>
        <List.Item>
          <List.Content floated='right'>
            <Button onClick={() => this.setState({showKanji:!this.state.showKanji})}>
              <Show show={this.state.showKanji} />
            </Button>
          </List.Content>
          <List.Content>
            {( (this.state.showKanji && word.questioned != 'jp') 
            || this.state.checking) && word.jp}
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content floated='right'>
            <Context.Consumer>{_ => <Button onClick={() => {
              if(this.state.showHiragana)
              {
                return this.setState({showHiragana: false});
              }
              if(isUsingKatakana)
              {
                this.setState({
                  showHiragana:true
                })
                _.useKatakana(false);
                return;
              }
              this.setState({
                showHiragana:true
              })
              _.useKatakana(true);
            }
            }>
              <Show show={this.state.showHiragana} />
            </Button>}
            </Context.Consumer>
          </List.Content>
          <List.Content>
            {((this.state.showHiragana  && word.questioned != 'jp')
            || this.state.checking) && <Hiragana isUsingKatakana={isUsingKatakana} hiragana={word.hiragana}/>}
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Content floated='right'>
            <Button onClick={() => this.setState({showRomaji:!this.state.showRomaji})}>
              <Show show={this.state.showRomaji} />
            </Button>
          </List.Content>
          <List.Content>
            {((this.state.showRomaji  && word.questioned != 'jp')
            || this.state.checking) && hepburn.fromKana(word.hiragana).toLowerCase()}
          </List.Content>
        </List.Item>
        <List.Item className='translation'>
          <List.Content floated='right'>
            <Button onClick={() => this.setState({showTranslation:!this.state.showTranslation})}>
              <Show show={this.state.showTranslation} />
            </Button>
          </List.Content>
          <List.Content>
            {((this.state.showTranslation && word.questioned != 'fr')
            || this.state.checking) && word.fr}
            <div>{this.state.checking && word.detail}</div>
          </List.Content>
        </List.Item>
      </List>
      {this.displayArrows()}
    </div>
  }
}

export default Word
